<template>
	<div class="qr-gates-table pb-3">
		<v-data-table
			class="overflow-x elevation-1"
			height="100%"
			item-key="_id"
			hide-default-footer
			:headers="headers"
			:items="computedItems"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			@click:row="handleRowClick"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<!-- Кнопка создания -->
							<Button class="mr-5" small @click.native="showModalCreate = true">
								<v-icon class="mr-1" small>mdi-plus</v-icon>
								<span class="body-2 font-weight-light text-uppercase">
									Создать
								</span>
							</Button>
							<!-- Поиск по названию въезда -->
							<div class="search">
								<TextField
									v-model="filter.name"
									placeholder="Название калитки"
									@change="updateQuery"
								>
								</TextField>
							</div>
						</div>
					</template>
				</v-data-footer>
			</template>

			<template v-slot:[`item.houses`]="{ item }">
				<td>{{ getAddress(item) }}</td>
			</template>

			<template v-slot:[`item.edit`]="{ item }">
				<td>
					<v-btn color="primary" icon @click.stop="editEntry(item)">
						<v-icon small>mdi-pencil</v-icon>
					</v-btn>
				</td>
			</template>
		</v-data-table>
		<!-- Cоздание -->
		<QrTableGatesModal
			v-if="showModalCreate"
			@close-modal="showModalCreate = false"
			@update-info="updateQuery"
		/>
		<!-- Редактирование -->
		<QrTableGatesModal
			v-if="showModalEdit"
			:toEdit="toEdit"
			@update-info="updateQuery"
			@close-modal="showModalEdit = false"
		/>
	</div>
</template>

<script>
import { timestampToDdmmyyyy } from "@/helpers/helpers";
import { mapGetters } from "vuex";

import QrTableEntriesModal from "@/components/QrTableEntriesModal";
import QrTableGatesModal from "@/components/QrTableGatesModal";

export default {
	name: "QrTableGates",

	components: {
		QrTableGatesModal,
		QrTableEntriesModal,
	},

	data() {
		return {
			toEdit: null,
			items: [],
			filter: {
				name: "",
			},
			filtersQuery: {
				"apartment_number": "apartment_number_search",
				"category_id": "request_category_id_search",
			},
			headers: [
				{ text: "Калитка", value: "name", sortable: false },
				{ text: "Адреса", value: "houses", sortable: false },
				{
					text: "Имя сети",
					value: "wifi_name",
					sortable: false,
				},
				{
					text: "Пароль сети",
					value: "wifi_password",
					sortable: false,
				},
				{
					text: "Действия",
					value: "edit",
					sortable: false,
				},
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: -1,
				pageStart: 1,
				pageStop: 0,
				pageCount: 0,
				itemsLength: 0,
			},
			loading: true,
			showModalCreate: false,
			showModalEdit: false,
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			role: "auth/GET_USER_ROLE_VUEX",
		}),
		computedItems() {
			return this.items.filter(e =>
				e.name?.toLowerCase().includes(this.filter.name.toLowerCase())
			);
		},
	},

	watch: {
		options: {
			handler() {
				const query = { ...this.$route.query, page: this.options.page };
				this.$router.replace({ query });
				this.loadData(query);
			},
			deep: true,
		},
	},

	methods: {
		timestampToDdmmyyyy,

		editEntry(item) {
			this.toEdit = item;
			this.showModalEdit = true;
		},

		getAddress(item) {
			return item.houses.map(e => e.apartment_address).join(", ") || "---";
		},

		// ? Методы таблицы
		handleRowClick(e) {
			// this.$router.push({
			// 	path: `/qr/whitelist`,
			// 	query: { ...this.$route.query, entry_id: e._id },
			// });
		},

		async resetFilters() {
			for (const key in this.filter) {
				this.filter[key] = "";
			}
			this.$router.replace({ query: null });
			await this.loadData({});
		},

		async loadData(params) {
			this.loading = true;
			return this.$api[this.role]
				.load_gates(params)
				.then(res => {
					this.items = res.records;
					this.options.itemsLength = this.items.length;
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		async updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			await this.loadData(query);
		},
		// ? Конец методов таблицы
	},

	async created() {
		this.$store.dispatch("ksk/load_houses");
		this.$store.dispatch("ksk/load_employee");
	},

	async mounted() {
		try {
			const query = { ...this.$route.query };
			await this.loadData(query);
			this.loading = false;
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.whitelist-table {
	height: 100vh;
}
</style>
